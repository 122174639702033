import * as EventEmitter from "events";
import ConnectivityHolder, { ConnectivityDatapoint } from "../ConnectivityHolder";
import AbstractDispatcher from "./AbstractDispatcher";
import {
  AbstractDatapoint,
  Connector,
  DefaultDatapointTypes,
  Datapoint,
  EventDatapointParameters,
  JSAPIDatapointParameters,
  TYPE,
  ConnectorParameters,
  ConnectableElement,
  ConnectorTask,
  JSONObject,
  ConnectorInstance,
} from "@olive/oli-types";
import ConnectivityManager from "../ConnectivityManager";
import BroadcastDispatcher from "./BroadcastDispatcher";
import WindowDispatcher from "./WindowDispatcher";
import APIManager from "../APIManager";
import { isEmpty, isString } from "lodash";
import * as JsonataModule from "jsonata";
import {isConnectorDefinition, isConnectorInstance, isConnectorTask, isDatapointDefinition, isDatapointInstance} from "../../utils/connectivityUtil";
import {getDatapointFromConnectorElement} from "../utils";

const jsonata = JsonataModule.default;

const SUPPORTED_TYPES = [
  "olive",
  "event",
  "jsAPI",
  "windowmessage",
  "broadcast",
];

export const CLIENT_DISPATCHER_ID = "client-dispatcher";

export default class ClientDispatcher extends AbstractDispatcher {
  eventManager: EventEmitter;
  apiManager: APIManager;
  connectivityManager: ConnectivityManager;
  broadcastDispatcher: BroadcastDispatcher;
  windowDispatcher: WindowDispatcher;

  constructor(
    connectivityHolder: ConnectivityHolder,
    eventManager: EventEmitter,
    apiManager: APIManager,
    connectivityManager: ConnectivityManager
  ) {
    super(connectivityHolder);
    this.eventManager = eventManager;
    this.apiManager = apiManager;
    this.connectivityManager = connectivityManager;
    this.broadcastDispatcher = new BroadcastDispatcher(
      connectivityHolder,
      connectivityManager
    );
    this.windowDispatcher = new WindowDispatcher(
      connectivityHolder,
      connectivityManager
    );
  }

  async dispatch(props: { id: string; input?: object }): Promise<unknown> {
    let datapoint = this.connectivityHolder.get(props.id) as ConnectableElement;
    console.log(datapoint, datapoint.type);
    switch (datapoint.type) {
      case TYPE.CONNECTOR:
      case "connectorInstance":
        const result = await this.dispatchConnector(
          datapoint as Connector,
          props.input
        );
        return result;
      case TYPE.DATAPOINT:
        return await this.dispatchDatapoint(
          datapoint as ConnectivityDatapoint,
          props.input,
          props
        );
      default:
        console.warn(`Datapoint Type ${datapoint.type} not supported`);
        return `Datapoint Type ${datapoint.type} not supported`;
    }
  }

  canDispatch(props: { id: string }): boolean {
    try {
      let { datapoint, type } = this.connectivityHolder.get(props.id) as Datapoint;
      if ([TYPE.CONNECTOR, "connectorInstance"].includes(type)) {
        return true;
      }
      const datapointType = datapoint.type;
      return SUPPORTED_TYPES.includes(datapointType);
    } catch (error) {
      return false;
    }
  }

  canDispatchType(type: string): boolean {
    return SUPPORTED_TYPES.includes(type);
  }

  async dispatchConnector(
    connector: Connector | ConnectorInstance,
    input?: object
  ): Promise<unknown> {
    let connectorConfig: ConnectorParameters;
    if(isConnectorDefinition(connector))
    {
      connectorConfig = connector.connector;
    }
    else if(isConnectorInstance(connector))
    {
      connectorConfig = connector;
    }
    else
    {
      console.error("Unknown connector type: ", connector);
      return null;
    }

    console.log("connectorConfig: ", connectorConfig);
    let resultMap = {};
    let previousResult = input;
    let lastResult = null;
    for (let i=0; i < connectorConfig.elements.length; i++) {
      const datapoint = connectorConfig.elements[i];
      let ref: string = null;
      let parameters: JSONObject = null;
      if(isConnectorTask(datapoint) || isDatapointInstance(datapoint)) {
        ref = datapoint.ref;
        if(input && input[ref]) {
          parameters = { ...datapoint.parameters, ...input[ref]};
        }
        else
        {
          parameters = datapoint.parameters;
        }
      }
      const resolvedConnectivity = getDatapointFromConnectorElement(datapoint, this.connectivityHolder);

      // if no data point that returns a result just take the input as result
      if(!resolvedConnectivity || (isDatapointDefinition(resolvedConnectivity) && resolvedConnectivity.datapoint.type === DefaultDatapointTypes.EVENT)) {
        resultMap[ref] = input;
        continue;
      }

      let resolvedInstanceParameters = parameters;
      if (parameters) {
        resolvedInstanceParameters = await this.resolveInstanceParameters(parameters, resultMap);
      }
      const enrichedInput = {
        ...previousResult,
        ...resolvedInstanceParameters,
      };


      console.log("invoking endoint with----------", previousResult, parameters, enrichedInput);

      let result = await this.connectivityManager.invoke({
        id: resolvedConnectivity.id,
        parameters: resolvedInstanceParameters,
        input: enrichedInput,
      });

      lastResult = result;
      if (Array.isArray(result)) {
        result = { array: result };
      }

      resultMap[ref] = result;
      previousResult = resultMap[ref];
    }
    return lastResult;
  }
  
  private async resolveInstanceParameters(params: any, resultMap: {[key: string]: object}) {
    const newParams = { ...params };
    for (let key in newParams) {
      if (typeof newParams[key] === 'string' && newParams[key].startsWith('$')) {
        try {
          const expression = newParams[key];
          const result = await jsonata(expression).evaluate(resultMap);
          newParams[key] = result;
        } catch (error) {
          console.error(`Error evaluating jsonata expression: ${newParams[key]}`, error);
        }
      } else if (typeof newParams[key] === 'object' && newParams[key] !== null) {
        newParams[key] = await this.resolveInstanceParameters(newParams[key], resultMap);
      }
    }
    return newParams;
  }

  private async invokeDatapoint(
    datapointConfig: any,
    input?: unknown,
    isSource: boolean = true
  ): Promise<unknown> {
    const datapoint = {
      id: isString(datapointConfig)
        ? datapointConfig
        : isSource
          ? datapointConfig.source.id
          : datapointConfig.destination.instanceID || datapointConfig.destination.id,
      type: isString(datapointConfig)
        ? TYPE.CONNECTOR
        : isSource
          ? datapointConfig.source.type
          : datapointConfig.destination.type,
    };

    if (datapoint.type === TYPE.CONNECTOR || !this.canDispatchType(datapoint.type)) {
      const serverResult = await this.connectivityManager.invoke({
        id: datapoint.id,
        input,
      });
      return serverResult;
    }

    const connectivityDatapoint = this.connectivityHolder.get(datapoint.id) as Datapoint;
    const result = await this.connectivityManager.invoke({
      id: connectivityDatapoint.id,
      input,
    });

    return result;
  }

  async dispatchDatapoint(
    datapoint: ConnectivityDatapoint,
    input?: object,
    props?: { id: string; input?: object }
  ): Promise<unknown> {
    console.log(datapoint);

    if (datapoint.datapoint.type === DefaultDatapointTypes.EVENT) {
      const eventDatapoint = datapoint.datapoint as EventDatapointParameters;
      return this.dispatchEvent(eventDatapoint.event.topic, input);
    }
    if (datapoint.datapoint.type === DefaultDatapointTypes.JS_API) {
      //@ts-ignore TODO according to types this is wrong, check if actually needed and correct
      const parameters = props?.parameters;
      let componentInstanceID = "";
      if (parameters) {
        componentInstanceID = parameters.componentInstanceID;
      } else if (datapoint.parameters) {
        componentInstanceID = datapoint.parameters.componentInstanceID as string;
      }
      const { ...datapointRest } = datapoint.parameters;
      const { ...propsRest } = parameters || {};
      const jsAPIDatapoint = datapoint.datapoint as JSAPIDatapointParameters;
      return this.dispatchJSAPI({
        apiName: jsAPIDatapoint.jsAPI.api,
        componentInstanceID,
        input,
        props: { ...datapointRest, ...propsRest },
      });
    }
    if (this.broadcastDispatcher.canDispatch({ id: datapoint.id, datapoint })) {
      return await this.broadcastDispatcher.dispatch({
        id: datapoint.id,
        input,
      });
    }
    if (this.windowDispatcher.canDispatch({ id: datapoint.id, datapoint })) {
      return await this.windowDispatcher.dispatch({
        id: datapoint.id,
        input,
      });
    }
    console.warn(
      "Currently only event datapoints are supported by client dispatcher!"
    );
    return null;
  }


  dispatchEvent(topic: string, params: object) {
    this.eventManager.emit(topic, params);
  }

  dispatchJSAPI({
    apiName,
    componentInstanceID,
    input,
    props,
  }: {
    apiName: string;
    componentInstanceID: string;
    input: object;
    props: object;
  }) {
    const apiID = componentInstanceID + "-" + apiName;
    const api = this.apiManager.get(apiID);
    if (api) {
      return api({
        ...props,
        ...input
      });
    } else {
      console.error("Could not find api with id " + apiID);
    }
  }
}
