export default class APIManager {
  apis: { [key: string]: (...args: any[]) => any }

  constructor() {
    this.apis = {};
  }

  add(key: string, fn: () => any) {
    if (typeof (fn) !== "function") {
      console.error("Added non-function to APIManager with key " + key);
    }
    console.info("Registered API " + key, fn);
    this.apis[key] = fn;
  }

  get(key: string) {
    return this.apis[key];
  }
}