import * as EventEmitter from "events";
import DefaultEvents from "../utils/DefaultEvents";

export default class PiletLoadTracker
{
  piletForPage: {
    [page: string]: string[]
  }
  initializedPilets: string[]
  currentPage: string
  eventMgr: EventEmitter

  constructor({eventMgr, initialPage}: {eventMgr: EventEmitter, initialPage: string})
  {
    this.piletForPage = {};
    this.initializedPilets = [];
    this.currentPage = initialPage;
    this.eventMgr = eventMgr;
    
    eventMgr.on(DefaultEvents.ON_PILET_ADDED_TO_PAGE, this.onAddPiletToPage.bind(this));
    eventMgr.on(DefaultEvents.ON_PILET_INITIALIZED, this.onPiletInitialized.bind(this));
    eventMgr.on(DefaultEvents.PAGE_CHANGE, this.onPageChange.bind(this));

  }

  onAddPiletToPage({id, page}: {id: string, page: string})
  {
    if(!this.piletForPage[page])
    {
      this.piletForPage[page] = [];
    }
    this.piletForPage[page].push(id);
  }

  onPageChange({route}: {route: string})
  {
    this.initializedPilets = [];
    this.currentPage = route;
  }

  onPiletInitialized(id: string)
  {
    this.initializedPilets.push(id);
    if(this.piletForPage[this.currentPage]?.length === this.initializedPilets.length)
    {
      console.info("**************** ALL PILETS FOR THIS PAGE INITIALIZED *******************");
      this.eventMgr.emit(DefaultEvents.ON_ALL_PILETS_INITIALIZED);
    }
  }

}