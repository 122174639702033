import { ExtensionSlot } from 'piral-core';
import React from 'react';

const createPage = ({ pageKey, layoutType = "", components = [] }: { pageKey: string, layoutType: string, components: object[] }) => {
  return (props: unknown) => {
    switch (layoutType) {
      case "single":
        return (
          <div>
            <ExtensionSlot name={`${pageKey}-default`} params={props} />
          </div>
        );
      case "wizard":
        return (<div>
          <ExtensionSlot name={`${pageKey}-stepper`} params={props} />
          <ExtensionSlot name={`${pageKey}-content`} params={props} />
        </div>)
      case "chat":
        return (<div style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}>
          <div style={{
            flex: 1,
            overflowY: "auto",
          }}>
            <ExtensionSlot name={`${pageKey}-content`} params={props} /></div>
          <ExtensionSlot name={`${pageKey}-input`} params={props} />
        </div>)
      case "hbox":
        return (
          <div style={{
            display: "flex",
            flexDirection: "row",
          }}>
            {components.map((_component, index) => (
              <ExtensionSlot key={index} name={`${pageKey}-hbox-${index + 1}`} params={props} />
            ))}
          </div>
        )
      case "vbox":
        return (
          <div style={{
            display: "flex",
            flexDirection: "column",
          }}>
            {components.map((_component, index) => (
              <ExtensionSlot key={index} name={`${pageKey}-vbox-${index + 1}`} params={props} />
            ))}
          </div>
        )
      case "compare":
        return (<div style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
        }}>
          <div style={{
            flex: 1,
            overflowY: "auto",
          }}>
            <ExtensionSlot name={`${pageKey}-content`} params={props} /></div>
          <ExtensionSlot name={`${pageKey}-input-1`} params={props} /><br />
          <ExtensionSlot name={`${pageKey}-input-2`} params={props} />
        </div>)
      case "border":
        return (
          <div style={{ display: "table", padding: 20, height: '100%' }}>
            <div style={{ display: "table-row", height: '100%' }}>
              <div style={{ display: "table-cell", width: 200, height: '100%' }}> <ExtensionSlot name={`${pageKey}-east`} params={props} /></div>
              <div style={{ display: "table-cell", width: '100%', height: '100%' }}> <ExtensionSlot name={`${pageKey}-west`} params={props} /></div>
              <div style={{ display: "table-cell", width: 200, height: '100%' }}> <ExtensionSlot name={pageKey} params={props} /></div>
            </div>
          </div>
        );
      default:
        return (<div>Layout type {layoutType} not supported</div>)
    }
  };
}

export default {
  createPage,
}

