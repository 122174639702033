class Environment {

  envVars:{[key: string]: string}  = {};

  set(key: string, value: string) {
    this.envVars[key] = value;
  }

  get(key: string): string {
    return this.envVars[key];
  }


}

export enum ENV_VARS {
  CONFIG_ID = "configID"
}

export default new Environment();