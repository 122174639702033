import {Connector, ConnectorInstance, Datapoint, DatapointInstance,  InlineDatapoint, InlineDataPointInstance, ConnectableElement, InlineConnectableElement, ConnectorElement, ConnectorTask, Workflow, InlineConnector} from "@olive/oli-types";

export function isConnectorInstance(instance: Connector | ConnectorInstance | DatapointInstance | Datapoint | InlineDataPointInstance ): instance is ConnectorInstance
{
  return instance.type === 'connectorInstance';
}

export function isDatapointInstance(instance: string | Connector | ConnectorInstance | DatapointInstance | Datapoint | InlineDataPointInstance ): instance is DatapointInstance
{
  return typeof(instance) !== "string" && instance.type === 'datapointInstance';
}

export function isConnectorDefinition(definition: Connector | ConnectorInstance | InlineConnector | Datapoint | Workflow ): definition is Connector
{
  return definition.type === 'connector';
}

export function isDatapointDefinition(definition: Connector | ConnectorInstance | Datapoint | InlineDataPointInstance | Workflow ): definition is Datapoint
{
  return definition.type === 'datapoint';
}

export function isConnectorTask(definition: ConnectorElement): definition is ConnectorTask {
  //@ts-ignore
  return definition.element;
}

export function isInlineDatapoint(definition: ConnectableElement | InlineConnectableElement): definition is InlineDatapoint {
  return definition.type && !definition.id && definition.type !== 'connector' && definition.type !== 'datapoint';
}

export function isInlineDatapointInstance(definition: ConnectorInstance | DatapointInstance | InlineDataPointInstance): definition is InlineDataPointInstance {
  //@ts-ignore
  return definition.type && !definition.datapoint && !definition.elements;
}


// export function isInlineConnector(definition): definition is InlineConnector {
//   return definition.type === 'connector' && !definition.id;
// }
// export function isInlineConnectorInstance(definition): definition is InlineConnectorInstance {
//   return definition.type === 'connector' && !definition.id;
// }