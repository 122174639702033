import * as EventEmitter from 'events';
import React, { useEffect, useLayoutEffect, useState, forwardRef } from 'react';
import DefaultEvents from './utils/DefaultEvents';

const ExtensionComponent = ({ id, extensionProps, ...other }: {id: string, extensionProps: {
  eventMgr: EventEmitter,
  props: object,
  children: object
  component: React.ComponentType<any>
}, other: object}) => {

  useLayoutEffect(() => {
    extensionProps.eventMgr.emit(DefaultEvents.ON_PILET_INITIALIZED, {id});

    console.info("layout rendered: " + id);
  });

  const props = { ...extensionProps.props, ...other };

  return (
    <extensionProps.component {...props}>
      {extensionProps.children}
    </extensionProps.component>
  );
}

export default ExtensionComponent;